@import '../theme-bootstrap';

.elc-responsive-modal-mask {
  .elc-product {
    .notify-me-confirmation-content {
      @media #{$cr19-large-up} {
        padding: 100px 0;
      }
    }
    &-notify-me-content,
    &-notify-me-confirmation-content {
      padding: 60px 20px;
      @media #{$cr19-large-up} {
        padding: 50px 20px 20px;
      }
    }
    &-notify-me-pop-up-wrapper,
    &-notify-me-confirmation-wrapper {
      transform: none;
      #{$rdirection}: 0;
      #{$ldirection}: auto;
      height: 100%;
      top: 0;
      @media #{$cr19-large-up} {
        top: 22%;
        height: auto;
        width: 420px;
        min-height: 432px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
    &-notify-me-confirmation-wrapper {
      .elc-product-notify-me-confirmation {
        &-title {
          font-size: 30px;
        }
        &-content-text {
          @include text-body-text--large;
          padding: 10px 0 0;
        }
      }
    }
    &-notify-me-content {
      .elc-product-notify-me {
        &-title-label {
          font-size: 28px;
          font-family: $base-bolder-font-family;
          letter-spacing: 0;
          line-height: 1;
        }
        &-description-label {
          font-family: $base-font-family-roman;
          font-size: 14px;
          margin-top: 15px;
          letter-spacing: 1px;
        }
        &-submit-button-disabled {
          width: 100%;
          background-color: $cr19-border-light;
          border-color: $cr19-border-light;
          pointer-events: none;
          &:hover,
          &:active,
          &:visited {
            background-color: $cr19-border-light;
            border-color: $cr19-border-light;
            color: $cr19-text-white;
          }
        }
        &-info-your-data {
          line-height: 1;
          padding-#{$ldirection}: 5px;
          font-size: 12px;
          font-family: $base-font-family-roman;
        }
        &-gdpr-notice-label {
          font-family: $base-font-family-roman;
          font-size: 14px;
        }
      }
      .elc-product-gdpr-popup-information {
        color: $color-white;
        font-size: 14px;
        font-family: $base-font-family-roman;
      }
      .elc-floating-label-input-error {
        .elc-floating-label-input-field {
          border-color: $cr19-error;
          color: $cr19-error;
        }
        .elc-floating-label__label-wrapper {
          span {
            color: $cr19-error;
          }
        }
      }
      .elc-input-error {
        padding: 0;
        margin: 0;
        min-height: auto;
        p {
          padding: 5px 0;
          margin: 0;
          font-size: 12px;
          color: $cr19-error;
          font-family: $base-font-family-roman;
        }
      }
    }
    &-notify-me-notice {
      align-items: center;
      .elc-checkbox-icon {
        margin-#{$ldirection}: -3px;
      }
      .elc-checkbox-check-area {
        top: 3px;
      }
    }
    &-notify-me-confirmation-content {
      max-width: none;
      text-align: #{$ldirection};
    }
    &-notify-me-title-label,
    &-notify-me-description-label,
    &-notify-me-gdpr-notice-label,
    &-notify-me-confirmation-title,
    &-notify-me-confirmation-content-text,
    &-notify-me-info-your-data {
      color: $color-black;
    }
    &-notify-me-notice-wrapper {
      padding-top: 15px;
      .elc-product-notify-me-gdpr-read-confirmation {
        color: $cr19-error;
        font-size: 12px;
        font-family: $base-font-family-roman;
        [dir='rtl'] & {
          text-align: $ldirection;
        }
      }
    }
    &-notify-me-info {
      padding-top: 15px;
      position: relative;
      align-items: center;
      &-icon {
        width: 22px;
        height: 22px;
      }
      &-button {
        background: transparent;
        padding: 0;
        height: auto;
        width: 15px;
        @media #{$cr19-large-up} {
          min-width: 15px;
        }
        &:hover {
          background: transparent;
        }
        &::after {
          background: url('#{$base-theme-path}img/icons/src/information.svg') no-repeat center;
          #{$rdirection}: 0;
          content: '';
          display: block;
          height: 15px;
          width: 15px;
        }
        .elc-product-notify-me-info-icon {
          display: none;
        }
      }
    }
    &-gdpr-popup {
      position: absolute;
      bottom: 25px;
      display: none;
      background-color: $color-cl-soft-black;
      border: 1px solid $color-cl-soft-black;
      color: $color-white;
      width: 100%;
      &.active {
        display: block;
      }
      p {
        margin: 0;
      }
      a {
        @include text-link--style-3;
      }
    }
    &-notify-me-confirmation-icon {
      display: none;
    }
    &-notify-me-form-wrapper {
      margin: 20px 0 0;
      .elc-input-field {
        height: 56px;
        @media #{$cr19-large-up} {
          height: 64px;
        }
      }
    }
    &-notify-me-submit-button,
    &-notify-me-confirmation-continue-button {
      @include elc-button;
      @include elc-button--dark;
      &.elc-button {
        width: 100%;
        height: 56px;
        line-height: 1;
        text-transform: none;
        font-family: $base-bolder-font-family;
        margin-top: 15px;
        @media #{$cr19-large-up} {
          height: 64px;
        }
      }
    }
    &-notify-me-submit-button {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      &-disabled {
        width: 100%;
        background-color: $cr19-border-light;
        border-color: $cr19-border-light;
        pointer-events: none;
        &:hover,
        &:active,
        &:visited {
          background-color: $cr19-border-light;
          border-color: $cr19-border-light;
          color: $cr19-text-white;
        }
      }
    }
    &-notify-me-confirmation-continue-button {
      position: relative;
      margin: 25px 0;
    }
    &-notify-me-confirmation-close-button,
    &-notify-me-pop-up-close-button {
      background: transparent;
      display: flex;
      justify-content: flex-end;
      margin: 12px 20px;
      padding: 6px;
      #{$rdirection}: 0;
      width: fit-content;
      @media #{$cr19-large-up} {
        position: absolute;
        top: 0;
      }
    }
  }
}
